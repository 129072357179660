import { useDailyUsage, useDailyIntervalParams } from "./interval";
import { useCallback, useMemo } from "react";
import dayjs from "dayjs";
import { IntervalBody } from "./IntervalBody";
import { DailyUsageDataResponse } from "@Light/services/lightTypes";

export type DailyIntervalBodyProps = {};

export const DailyIntervalBody: React.FC<DailyIntervalBodyProps> = ({}) => {
  const dailyUsage = useDailyUsage();
  if (!dailyUsage.isSuccess) {
    return null;
  }

  return <DailyIntervalBodyB1 dailyUsage={dailyUsage.data} />;
};

export type DailyIntervalBodyB1Props = {
  dailyUsage: DailyUsageDataResponse;
};

export const DailyIntervalBodyB1: React.FC<DailyIntervalBodyB1Props> = ({
  dailyUsage,
}) => {
  const { selectedMonth } = useDailyIntervalParams();
  const days = useMemo(() => {
    const startOfMonth = selectedMonth.startOf("month");
    const endOfMonth = selectedMonth.endOf("month");
    const days = [];
    for (
      let date = startOfMonth;
      date.isBefore(endOfMonth) || date.isSame(endOfMonth);
      date = date.add(1, "day")
    ) {
      days.push(date);
    }
    return days;
  }, [selectedMonth]);
  const labels = useMemo(() => days.map((day) => day.format("D")), [days]);
  const data = useMemo(() => {
    return dailyUsage.days.map((day) => ({
      label: dayjs(day.date).format("D"),
      consumption: parseFloat(day.consumption),
      vehicle_charging: parseFloat(day.vehicle_charging),
      eligible_vehicle_charging: parseFloat(day.eligible_vehicle_charging),
    }));
  }, [dailyUsage.days]);
  const title = useCallback(
    (items: { dataIndex: number }[]) => {
      const dataIndex = items[0]?.dataIndex;
      if (dataIndex === undefined) {
        return "";
      }
      const day = dailyUsage.days[dataIndex];
      if (!day) {
        return "";
      }
      return dayjs(day?.date).format("MMM D");
    },
    [dailyUsage.days],
  );
  return <IntervalBody title={title} labels={labels} data={data} />;
};
