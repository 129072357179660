export function SolarGridIcon(props: React.ComponentPropsWithoutRef<"svg">) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
        fill="#F0F9FF"
      />
      <g clipPath="url(#clip0_4885_8841)">
        <path
          d="M13.6667 17.7777C13.6667 18.6368 14.3632 19.3333 15.2223 19.3333H16.6667C17.5872 19.3333 18.3334 18.5871 18.3334 17.6666C18.3334 16.7461 17.5872 15.9999 16.6667 15.9999H15.3334C14.4129 15.9999 13.6667 15.2537 13.6667 14.3333C13.6667 13.4128 14.4129 12.6666 15.3334 12.6666H16.7778C17.6369 12.6666 18.3334 13.363 18.3334 14.2221M16 11.6666V12.6666M16 19.3333V20.3333M22.6667 15.9999C22.6667 19.6818 19.6819 22.6666 16 22.6666C12.3181 22.6666 9.33337 19.6818 9.33337 15.9999C9.33337 12.318 12.3181 9.33325 16 9.33325C19.6819 9.33325 22.6667 12.318 22.6667 15.9999Z"
          stroke="#026AA2"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4885_8841">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
