import { useCallback } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  TooltipItem,
} from "chart.js";
import { ChartDataProps, useChartData } from "./chartData";
Chart.register(CategoryScale, LinearScale, BarElement, Tooltip);

export type IntervalChartProps = ChartDataProps & {
  title: (items: TooltipItem<"bar">[]) => void;
};

export const IntervalChart: React.FC<IntervalChartProps> = ({
  title,
  ...dataProps
}) => {
  const { data } = dataProps;
  const chartData = useChartData(dataProps);
  const label = useCallback(
    (context: { dataIndex: number; datasetIndex: number }) => {
      if (context.datasetIndex > 0) {
        return "";
      }
      const datum = data[context.dataIndex];
      if (!datum) {
        return "";
      }
      const formatted = Intl.NumberFormat("en-US", {
        maximumFractionDigits: 0,
      }).format(datum.consumption);
      return `${formatted} kWh`;
    },
    [data],
  );
  return (
    <div className="w-full aspect-[2/1]">
      <Bar
        data={chartData}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              position: "right",
              title: {
                display: true,
                text: "kWh",
              },
              border: { display: false },
            },
            x: {
              border: { display: false },
              ticks: {
                maxRotation: 0,
                minRotation: 0,
                autoSkip: true,
                maxTicksLimit: 8,
              },
            },
          },
          plugins: {
            tooltip: {
              enabled: true,
              displayColors: false,
              position: "nearest",
              yAlign: "bottom",
              callbacks: {
                title,
                label,
              },
              filter: (context) => context.raw !== null,
            },
          },
        }}
      />
    </div>
  );
};
