import { Navigate, Route, Routes } from "react-router-dom";
import { WithConfiguration, useConfiguration } from "./WithConfiguration";
import { LoggedInApp } from "@Light/components/App";
import { LoginRoutes } from "./login/LoginRoutes";
import { WithAccount } from "@Light/components/page/account";
import { useLight } from "@Light/services/light";
import { Overlay } from "@Light/components/page/Overlay";

export const AppRoutes: React.FC = () => {
  return (
    <WithConfiguration>
      <ConfigurationRoutes />
    </WithConfiguration>
  );
};

export const ConfigurationRoutes: React.FC = () => {
  const configuration = useConfiguration();
  const { useGetAccountQuery } = useLight();
  const account = useGetAccountQuery();

  if (!configuration || (!account.isSuccess && !account.isError)) {
    // TODO: Loading? Handling for nonexistant configuration?
    return null;
  }

  if (account.isError) {
    return (
      <Routes>
        <Route path="/login/*" element={<LoginRoutes />} />
        <Route path="/*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  return (
    <Overlay>
      <WithAccount>
        <LoggedInRoutes />
      </WithAccount>
    </Overlay>
  );
};

export type LoggedInRoutesProps = {};

export const LoggedInRoutes: React.FC<LoggedInRoutesProps> = ({}) => {
  return (
    <Routes>
      <Route
        path="/test-error"
        element={
          <div
            onClick={() => {
              throw new Error("Test Error");
            }}
          >
            Test Error
          </div>
        }
      />
      <Route path="/*" element={<LoggedInApp />} />
    </Routes>
  );
};
