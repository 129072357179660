import { useScaffold } from "@Light/scaffold";
import { Card } from "./Card";
import clsx from "clsx";
import { useRate } from "@Light/utils/plan";

export type SolarBuybackWithStorageCardProps = {};

export const SolarBuybackWithStorageCard: React.FC<
  SolarBuybackWithStorageCardProps
> = ({}) => {
  const scaffold = useScaffold();
  const rate = useRate();
  const storageCreditDollars = parseFloat(rate.storage_monthly_credit_dollars);
  if (!storageCreditDollars) {
    return null;
  }

  return (
    <Card label="Battery storage credit">
      <div className="flex flex-col gap-3">
        <div>
          <span
            className={clsx(
              "text-2xl font-medium",
              scaffold.page.colors.text.primary,
            )}
          >
            $
            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2 }).format(
              storageCreditDollars,
            )}
          </span>
          <span
            className={clsx(
              "text-lg font-medium",
              scaffold.page.colors.text.primary,
            )}
          >
            /mo
          </span>
        </div>
        <div
          className={clsx(
            "text-sm",
            "font-normal",
            scaffold.page.colors.text.tertiary,
          )}
        >
          Our solar buyback + storage plan rewards you for using your battery to
          balance the grid.
        </div>
      </div>
    </Card>
  );
};
