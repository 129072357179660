import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { Card } from "../ConfirmRate/Card";
import { SolarBuybackIcon } from "../ConfirmRate/SolarBuybackIcon";
import { NoTerminationIcon } from "../ConfirmRate/NoTerminationIcon";
import { SolarGridIcon } from "../ConfirmRate/SolarGridIcon";
import { RenewableIcon } from "../ConfirmRate/RenewableIcon";
import { FixedRateIcon } from "../ConfirmRate/FixedRateIcon";
import { VehicleChargingIcon } from "../ConfirmRate/VehicleChargingIcon";
import { usePlan } from "@Light/utils/plan";
import { CircleIcon } from "@Light/components/page/CircleIcon";
import { Battery100Icon } from "@heroicons/react/24/solid";

export type OfferSummaryProps = {
  label?: string;
};

export const OfferSummary: React.FC<OfferSummaryProps> = ({ label }) => {
  const plan = usePlan();
  const scaffold = useScaffold();
  const {
    solarBuyback,
    storageCredit,
    vehicleCharging,
    fixedRate,
    noCancellationFees,
    renewableEnergy,
  } = plan.features;

  if (
    !solarBuyback &&
    !storageCredit &&
    !vehicleCharging &&
    !fixedRate &&
    !noCancellationFees &&
    !renewableEnergy
  ) {
    return null;
  }

  return (
    <Card label={label ?? "You're one step from having"}>
      <div
        className={clsx(
          "flex flex-col gap-2",
          "text-sm",
          scaffold.page.colors.text.secondary,
        )}
      >
        {solarBuyback && (
          <Item>
            <SolarBuybackIcon />
            <div>High value for solar buyback</div>
          </Item>
        )}
        {storageCredit && (
          <Item>
            <CircleIcon className="bg-lime-50">
              <Battery100Icon className="w-4 text-lime-700" />
            </CircleIcon>
            <div>Reward for balancing the grid</div>
          </Item>
        )}
        {(solarBuyback || storageCredit) && (
          <Item>
            <SolarGridIcon />
            <div>Credits that roll over and never expire</div>
          </Item>
        )}
        {vehicleCharging && (
          <Item>
            <VehicleChargingIcon />
            <div>
              Unlimited vehicle charging for {vehicleCharging.rateText}/month
            </div>
          </Item>
        )}
        {fixedRate && !solarBuyback && !storageCredit && (
          <Item>
            <FixedRateIcon />
            <div>
              A locked-in rate for the next {fixedRate.termMonths} months
            </div>
          </Item>
        )}
        {renewableEnergy && (
          <Item>
            <RenewableIcon />
            <div>100% renewable home energy</div>
          </Item>
        )}
        {noCancellationFees && (
          <Item>
            <NoTerminationIcon />
            <div>No early termination fee</div>
          </Item>
        )}
      </div>
    </Card>
  );
};

export type ItemProps = {
  children: React.ReactNode;
};

export const Item: React.FC<ItemProps> = ({ children }) => {
  return <div className="flex items-center gap-2">{children}</div>;
};
