import { useScaffold } from "@Light/scaffold";
import clsx from "clsx";
import { RateResponse } from "@Light/services/lightTypes";
import { usePlan } from "@Light/utils/plan";

export type DetailProps = {
  rate: RateResponse;
};

export const Detail: React.FC<DetailProps> = ({ rate }) => {
  const scaffold = useScaffold();
  const plan = usePlan();
  const { solarBuyback, storageCredit, vehicleCharging } = plan.features;
  const { storage_monthly_credit_dollars } = plan.plan;
  const Divided = scaffold.page.divided;
  return (
    <div className="flex flex-col gap-4">
      <div className={clsx("text-xs", scaffold.page.colors.text.tertiary)}>
        {solarBuyback ? (
          <>
            Pricing includes the energy charge, utility delivery charge, and
            monthly base charge
          </>
        ) : (
          <>
            Pricing includes the energy charge and the utility delivery charge
          </>
        )}
      </div>
      <Divided gap="0" className={clsx("border rounded-lg text-2xs")}>
        <div className="flex flex-row p-2 rounded-t-lg bg-[#F9FAFB]">
          <div className="w-2/5">Monthly usage</div>
          <div className="w-1/5">500 kWh</div>
          <div className="w-1/5">1000 kWh</div>
          <div className="w-1/5">2000 kWh</div>
        </div>
        <div className="flex flex-row px-2 py-3">
          <div className="w-2/5">Average price per kWh</div>
          <div className="w-1/5">{rate.avg_cents_per_kwh_500}¢</div>
          <div className="w-1/5">{rate.avg_cents_per_kwh_1000}¢</div>
          <div className="w-1/5">{rate.avg_cents_per_kwh_2000}¢</div>
        </div>
      </Divided>
      <Divided gap="0" className={clsx("border rounded-lg text-2xs")}>
        {solarBuyback && (
          <DetailRow label="Base charge">
            {solarBuyback.baseChargeText}/mo
          </DetailRow>
        )}
        {storageCredit && (
          <DetailRow label="Battery credit">
            -${storage_monthly_credit_dollars}/mo
          </DetailRow>
        )}
        {vehicleCharging && (
          <DetailRow label="Unlimited charging">
            {vehicleCharging.rateText}/mo
          </DetailRow>
        )}
        <DetailRow label="Energy charge">
          {rate.energy_rate_cents_per_kwh}¢/kWh
        </DetailRow>
        {solarBuyback && (
          <DetailRow label="Buyback rate">
            {rate.energy_rate_cents_per_kwh}¢/kWh
          </DetailRow>
        )}
        <DetailRow label={`${rate.tdu_shortname} fees`}>
          ${rate.delivery_base_monthly_dollars}/mo,{" "}
          {rate.delivery_rate_cents_per_kwh.replace(/0+$/, "")}¢/kWh
        </DetailRow>
      </Divided>
    </div>
  );
};

export type DetailRowProps = {
  label: string;
  children: React.ReactNode;
};

export const DetailRow: React.FC<DetailRowProps> = ({ label, children }) => {
  return (
    <div className="flex flex-row px-2 py-3">
      <div className="w-2/5">{label}</div>
      <div className="w-3/5">{children}</div>
    </div>
  );
};
