import { useLight } from "@Light/services/light";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isFetchBaseQueryError } from "@Light/utils/errors";

const posthogKey = import.meta.env.VITE_POSTHOG_KEY;

let flowAttribution = "";

export function setFlowAttribution(attribution: string) {
  flowAttribution = attribution;
}

let initialized = false;

if (typeof window !== "undefined" && posthogKey) {
  posthog.init(posthogKey, {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only",
    capture_pageview: false,
    session_recording: {
      maskAllInputs: true,
      maskInputFn: (text, element) => {
        if (element?.dataset["record"] === "true") {
          return text;
        }
        return "*".repeat(text.trim().length);
      },
    },
  });

  initialized = true;
}

function logoutPosthog() {
  if (initialized) {
    posthog.reset();
  }
}

export function captureEvent(
  event: string,
  properties?: Record<string, string | boolean>,
) {
  if (initialized) {
    posthog.capture(event, properties);
  } else {
    console.log("captureEvent", event, properties);
  }
}

export default function PostHogRecordEvents() {
  const location = useLocation();
  const light = useLight();

  const {
    data: account,
    isError,
    error,
  } = light.endpoints.getAccount.useQueryState();

  // Track pageviews on route change
  useEffect(() => {
    if (initialized) {
      posthog.capture("$pageview", {
        $current_url: window.location.href,
      });
    }
  }, [location]);

  // Identify user on login
  useEffect(() => {
    if (account) {
      const properties: Record<string, string | boolean> = {
        email: account.email,
        app_uuid: account.app.uuid,
        app_name: account.app.name,
        app_is_sandbox: account.app.is_sandbox,
      };

      if (flowAttribution) {
        properties["flow_attribution"] = flowAttribution;
      }

      posthog.identify(account.uuid, properties);
    }
  }, [account]);

  // Log out user on logout
  useEffect(() => {
    if (isError && isFetchBaseQueryError(error) && error.status === 401) {
      logoutPosthog();
    }
  }, [isError, error]);

  // Detect tab/window close and capture event
  useEffect(() => {
    const handleBeforeUnload = () => {
      captureEvent("window_close");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return null;
}

interface AnalyticsProviderProps {
  children: ReactNode;
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
  return (
    <PostHogProvider client={posthog}>
      <>
        {children}
        <PostHogRecordEvents />
      </>
    </PostHogProvider>
  );
}
