import { useScaffold } from "@Light/scaffold";
import { useLight } from "@Light/services/light";
import { UpdateIdentityRequest } from "@Light/services/lightTypes";
import { useMutation } from "@Light/utils/mutation";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useAccount } from "@Light/components/page/account";
import dayjs from "dayjs";

export type VerifyIdentityProps = {};

export const VerifyIdentity: React.FC<VerifyIdentityProps> = ({}) => {
  const account = useAccount();
  const { useUpdateIdentityMutation } = useLight();
  const updateIdentity = useMutation<UpdateIdentityRequest>(
    useUpdateIdentityMutation,
  );
  const { register, handleSubmit } = useForm();

  const onSubmit = useCallback(
    handleSubmit(({ birthMonth, birthDay }) => {
      updateIdentity.mutate({ birth_date: `${birthMonth}/${birthDay}` });
    }),
    [handleSubmit, updateIdentity.mutate],
  );

  if (updateIdentity.isSuccess && account.enrollment.is_identity_verified) {
    return <Navigate to="/enroll/setup-payment" />;
  }

  const scaffold = useScaffold();
  const PageBody = scaffold.page.pageBody;
  const MutationButton = scaffold.page.mutationButton;
  const Field = scaffold.system.field;
  const Label = scaffold.system.label;
  const Select = scaffold.system.select;
  const Divided = scaffold.page.divided;
  const DetailField = scaffold.page.detail.detailField;

  return (
    <form onSubmit={onSubmit}>
      <PageBody
        title="Confirm your identity"
        subtitle="Providing your month and date of birth helps us verify who you are."
        progress="25%"
      >
        <Divided>
          <DetailField label="Contact information">
            <div id="account-name">
              {account.first_name} {account.last_name}
            </div>
            <div id="account-email">{account.email}</div>
          </DetailField>
          <div className="flex flex-col gap-6">
            <div className="flex flex-row gap-4">
              <Field className="flex flex-col gap-1 w-full">
                <Label>Month of birth</Label>
                <Select
                  defaultValue=""
                  {...register("birthMonth", { required: true })}
                >
                  <option value="" disabled hidden>
                    Month
                  </option>
                  {Array(12)
                    .fill(0)
                    .map((_, i) => {
                      const value = String(i + 1).padStart(2, "0");
                      const month = dayjs(`2024-${value}-01`).format("MMMM");
                      return (
                        <option key={value} value={value}>
                          {value} - {month}
                        </option>
                      );
                    })}
                </Select>
              </Field>
              <Field className="flex flex-col gap-1 w-full">
                <Label>Day of birth</Label>
                <Select
                  defaultValue=""
                  {...register("birthDay", { required: true })}
                >
                  <option value="" disabled hidden>
                    Day
                  </option>
                  {Array(31)
                    .fill(0)
                    .map((_, i) => {
                      const value = String(i + 1).padStart(2, "0");
                      return (
                        <option key={value} value={value}>
                          {value}
                        </option>
                      );
                    })}
                </Select>
              </Field>
            </div>
            <MutationButton
              mutation={updateIdentity}
              mutateButtonText="Confirm & continue"
              errorMessage="Failed submitting identity information. Please try again later"
            />
          </div>
        </Divided>
      </PageBody>
    </form>
  );
};
