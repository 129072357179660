import { useHeader } from "@Light/components/page/header/context";
import { useScaffold } from "@Light/scaffold";
import { Item } from "./Item";
import {
  BanknotesIcon,
  CreditCardIcon,
  XCircleIcon,
  MagnifyingGlassIcon,
  BoltSlashIcon,
  BoltIcon,
  LightBulbIcon,
  GlobeAsiaAustraliaIcon,
  SunIcon,
  ArrowUpTrayIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { usePlan } from "@Light/utils/plan";
import { Battery100Icon, Battery50Icon } from "@heroicons/react/24/solid";

export const FAQ: React.FC = () => {
  const { setBackNavigate } = useHeader();

  setBackNavigate("/support/");

  const scaffold = useScaffold();
  const Link = scaffold.system.link;
  return (
    <FAQBody
      contactWrapper={({ children }) => (
        <Link to="/support/contact/">{children}</Link>
      )}
    />
  );
};

export type FAQBodyProps = {
  contactWrapper: React.FC<{ children: React.ReactNode }>;
  footer?: React.ReactNode;
};

export const FAQBody: React.FC<FAQBodyProps> = ({ contactWrapper, footer }) => {
  const scaffold = useScaffold();

  const plan = usePlan();
  const {
    noCancellationFees,
    vehicleCharging,
    solarBuyback,
    storageCredit,
    renewableEnergy,
  } = plan.features;

  const PageBody = scaffold.page.pageBody;
  const Divided = scaffold.page.divided;
  const Button = scaffold.page.button;
  const ContactWrapper = contactWrapper;
  return (
    <PageBody
      title={
        <div className="flex flex-col justify-center text-center gap-4">
          Frequently asked questions
        </div>
      }
      footer={footer}
    >
      <Divided gap="6">
        <div className="flex flex-col gap-8 px-4">
          <Item
            icon={<MagnifyingGlassIcon className="w-4 h-4" />}
            title="What other fees are charged?"
          >
            We know it sounds too good to be true, but we promise there are no
            hidden fees. What you see is what you get—clear and transparent
            pricing with no surprises.
          </Item>
          {Boolean(noCancellationFees) && (
            <Item
              icon={<XCircleIcon className="w-4 h-4" />}
              title="What is your cancellation policy?"
            >
              We would be sad to see you go, but if you need to cancel your
              plan, you can do so at any time without incurring early
              termination fees.
            </Item>
          )}
          {Boolean(vehicleCharging) && (
            <Item
              icon={<Battery100Icon className="w-4 h-4" />}
              title="What are the hours for unlimited overnight car charging?"
            >
              You can charge your car at any time, but only charging between{" "}
              {vehicleCharging?.chargeStart} and {vehicleCharging?.chargeEnd} is
              included in the unlimited charging portion of the plan. If you
              charge outside these hours, you'll pay your plan’s regular rate
              per kWh for electricity.
            </Item>
          )}
          {Boolean(solarBuyback) && (
            <Item
              icon={<SunIcon className="w-4 h-4" />}
              title="Do I need to wait until my solar panels are installed to sign up?"
            >
              No, you can sign up with us before your system is active. By
              signing up early, you can ensure a smooth transition once your
              solar panels are installed.
            </Item>
          )}
          <Item
            icon={<BanknotesIcon className="w-4 h-4" />}
            title="How is my plan’s pricing calculated?"
          >
            Pricing for your plan is determined by your location and includes
            various energy components such as generation costs, transmission and
            distribution fees, and local taxes. You will have visibility into
            the plan components depending on the plan type.
          </Item>
          {Boolean(solarBuyback) && (
            <Item
              icon={<ArrowUpTrayIcon className="w-4 h-4" />}
              title="How does solar buyback work?"
            >
              When you generate more solar energy than you use, the excess
              energy is sent back to the grid. In return, you receive credits on
              your electricity bill, effectively reducing your overall energy
              costs. This process, known as solar buyback, helps you maximize
              the benefits of your solar panel system.
            </Item>
          )}
          {Boolean(storageCredit) && (
            <Item
              icon={<Battery100Icon className="w-4 h-4" />}
              title="How does my battery storage help balance the grid?"
            >
              Solar panels are great for generating electricity when the sun is
              shining, but there are still peak hours of consumption after the
              sun goes down and before it comes up. Battery storage helps to
              shift your demand from peak times to off-peak times.
            </Item>
          )}
          {(Boolean(solarBuyback) || Boolean(storageCredit)) && (
            <Item
              icon={<CurrencyDollarIcon className="w-4 h-4" />}
              title="How do bill credits work?"
            >
              We provide you with bill credits each month for the electricity
              you export back to the grid. There are no limits to the amount of
              credit you can earn, maximizing the benefits to you.
            </Item>
          )}
          {Boolean(storageCredit) && (
            <Item
              icon={<Battery50Icon className="w-4 h-4" />}
              title="Will I still be able to use my battery for power backup?"
            >
              While your battery will be used to balance your demand, it will
              never be drained below 20% capacity, so you will still have
              reserve power if the grid ever goes offline.
            </Item>
          )}
          {Boolean(renewableEnergy) && (
            <Item
              icon={<GlobeAsiaAustraliaIcon className="w-4 h-4" />}
              title={`How does ${scaffold.page.companyName} provide 100% renewable energy?`}
            >
              We match your electricity usage with local, renewable energy from
              wind and solar power on an annual basis. For every unit you use,
              we ensure the same amount is generated and added to the grid,
              supporting a cleaner, sustainable future.
            </Item>
          )}
          <Item
            icon={<CreditCardIcon className="w-4 h-4" />}
            title="How does billing work?"
          >
            With Autopay, your electricity bill is automatically charged to your
            credit card each month. The full bill amount will be processed
            within two business days of the due date.
          </Item>
          <Item
            icon={<BoltSlashIcon className="w-4 h-4" />}
            title={`What do I need to do to get ${scaffold.page.companyName}?`}
          >
            To get an electricity plan, simply sign up online. We'll handle the
            switch from your current provider with no service interruption or
            home installation needed. No need to contact your current
            provider—just pay their final bill when you receive it.
          </Item>
          <Item
            icon={<BoltIcon className="w-4 h-4" />}
            title={`How does ${scaffold.page.companyName} provide electricity service?`}
          >
            When you sign up for a plan with {scaffold.page.companyName}, your
            service is provided by Light Energy (Public Utility Commission of
            Texas REP #10340). {scaffold.page.companyName} collaborates with
            Light to offer you transparent, affordable electricity, all managed
            through the {scaffold.page.companyName} platform, including billing
            and customer support.
          </Item>
          <Item
            icon={<LightBulbIcon className="w-4 h-4" />}
            title="Do I need an electrician, new hardware, or wires to my home?"
          >
            No, we use your existing electrical infrastructure. Electricity is
            delivered over the same wires you use today. No installations,
            rewiring, or electricians are needed.
          </Item>
        </div>
        <div className="rounded-lg bg-gray-100 p-4">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-2">
              <div
                className={clsx(
                  "font-semibold text-xl",
                  scaffold.page.colors.text.primary,
                )}
              >
                Still have questions?
              </div>
              <div className={clsx(scaffold.page.colors.text.secondary)}>
                Can’t find the answer you’re looking for? Chat with our friendly
                team.
              </div>
            </div>
            <div className="flex flex-row">
              <ContactWrapper>
                <Button>Get in touch</Button>
              </ContactWrapper>
            </div>
          </div>
        </div>
      </Divided>
    </PageBody>
  );
};
