import { useScaffold } from "@Light/scaffold";
import { clsx } from "clsx";
import { FixedRateIcon } from "./FixedRateIcon";
import { NoTerminationIcon } from "./NoTerminationIcon";
import { RenewableIcon } from "./RenewableIcon";
import { Card } from "./Card";
import { SolarBuybackIcon } from "./SolarBuybackIcon";
import { SolarGridIcon } from "./SolarGridIcon";
import { usePlan } from "@Light/utils/plan";
import { Battery100Icon } from "@heroicons/react/24/solid";

export type OfferSummaryProps = {};

export const OfferSummary: React.FC<OfferSummaryProps> = ({}) => {
  const scaffold = useScaffold();
  const plan = usePlan();
  const {
    solarBuyback,
    storageCredit,
    fixedRate,
    noCancellationFees,
    renewableEnergy,
  } = plan.features;
  if (!fixedRate && !noCancellationFees && !renewableEnergy) {
    return null;
  }
  const CircleIcon = scaffold.page.circleIcon;
  return (
    <Card label="Your plan includes">
      <div
        className={clsx(
          "flex flex-col gap-2",
          "text-sm",
          scaffold.page.colors.text.secondary,
        )}
      >
        {solarBuyback && (
          <Item>
            <SolarBuybackIcon />
            <div>High value for solar buyback</div>
          </Item>
        )}
        {storageCredit && (
          <Item>
            <CircleIcon className="bg-lime-50">
              <Battery100Icon className="w-4 text-lime-700" />
            </CircleIcon>
            <div>Reward for balancing the grid</div>
          </Item>
        )}
        {(solarBuyback || storageCredit) && (
          <Item>
            <SolarGridIcon />
            <div>Credits that roll over and never expire</div>
          </Item>
        )}
        {fixedRate && !solarBuyback && !storageCredit && (
          <Item>
            <FixedRateIcon />
            <div>
              A locked-in rate for the next {fixedRate.termMonths} months
            </div>
          </Item>
        )}
        {renewableEnergy && (
          <Item>
            <RenewableIcon />
            <div>100% renewable home energy</div>
          </Item>
        )}
        {noCancellationFees && (
          <Item>
            <NoTerminationIcon />
            <div>No early termination fee</div>
          </Item>
        )}
      </div>
    </Card>
  );
};

export type ItemProps = {
  children: React.ReactNode;
};

export const Item: React.FC<ItemProps> = ({ children }) => {
  return <div className="flex items-center gap-2">{children}</div>;
};
