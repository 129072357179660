import { useLight } from "@Light/services/light";
import { RateGroup } from "@Light/services/lightTypes";
import { useMemo } from "react";

export function useRateGroup(plan?: string): RateGroup | undefined {
  const { useRateGroupsQuery } = useLight();
  const rateGroups = useRateGroupsQuery();
  const rateGroup = useMemo(() => {
    if (!rateGroups.isSuccess) {
      return undefined;
    }
    for (const rateGroup of rateGroups.data) {
      if (plan && rateGroup.uuid === plan) {
        return rateGroup;
      }
      if (!plan && rateGroup.is_default_for_app) {
        return rateGroup;
      }
    }
    return rateGroups.data?.[0];
  }, [rateGroups.isSuccess, rateGroups.data, plan]);
  if (!rateGroup) {
    return undefined;
  }

  return rateGroup;
}

export function formatHourString(hour: number, ampm: string): string {
  return `${hour} ${ampm}`;
}

export function hourString(hour: number): string {
  if (hour > 12) {
    return formatHourString(hour - 12, "PM");
  }

  if (hour === 12) {
    return formatHourString(hour, "PM");
  }

  if (hour === 0) {
    return formatHourString(12, "AM");
  }

  return formatHourString(hour, "AM");
}

export type PlanFeatures = {
  fixedRate?: {
    termMonths: number;
  };
  renewableEnergy?: {};
  noCancellationFees?: {};
  solarBuyback?: {
    baseChargeText: string;
  };
  storageCredit?: {};
  vehicleCharging?: {
    rateText: string;
    chargeStart: string;
    chargeEnd: string;
  };
};

export function planFeaturesForRateGroup(rateGroup: RateGroup): PlanFeatures {
  const planFeatures: PlanFeatures = {};

  if (rateGroup.term_months) {
    planFeatures.fixedRate = {
      termMonths: rateGroup.term_months,
    };
  }

  if (rateGroup.renewable_percent >= 100) {
    planFeatures.renewableEnergy = {};
  }

  if (rateGroup.early_termination_fee_dollars === "0.00") {
    planFeatures.noCancellationFees = {};
  }

  if (rateGroup.has_solar_buyback) {
    planFeatures.solarBuyback = {
      baseChargeText: `$${rateGroup.plan_monthly_cost_dollars}`,
    };
  }

  if (rateGroup.storage_monthly_credit_dollars) {
    planFeatures.storageCredit = {};
  }

  if (rateGroup.has_unlimited_ev_charging) {
    planFeatures.vehicleCharging = {
      rateText: `$${rateGroup.ev_monthly_cost_dollars}`,
      chargeStart: hourString(rateGroup.ev_charge_hour_start),
      chargeEnd: hourString(rateGroup.ev_charge_hour_end),
    };
  }

  return planFeatures;
}
