import { useAPI } from "@/services/api";
import { light } from "@/services/light";
import { useScaffold } from "@Light/scaffold";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export type LogoutButtonProps = {};

export const LogoutButton: React.FC<LogoutButtonProps> = ({}) => {
  const { useLogoutMutation } = useAPI();
  const [logout, logoutState] = useLogoutMutation();
  const dispatch = useDispatch();

  if (logoutState.isSuccess) {
    dispatch(light.util.invalidateTags(["Account"]));
  }

  const scaffold = useScaffold();
  const Button = scaffold.system.button;
  return (
    <Button onClick={useCallback(() => logout(), [logout])}>Log out</Button>
  );
};
